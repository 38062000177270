import ServiceForm from "../../../components/ServicesForm";

function PeriodicCleaningModal() {
  return (
    <div className="font-[poppins]">
      <h3 className="text-center text-[#23146D] text-[45px] font-[700]">
        Contract Cleaning/Industrial cleaning
      </h3>
      <div className="p-3 text-[#4F4F4F] text-[16px]">
        <p>
          Contract cleaning refers to a service provided by professional
          cleaning companies to businesses, organizations, or individuals under
          a contractual agreement. These contracts typically outline the scope
          of cleaning services to be provided, the frequency of cleaning, the
          duration of the contract, and the terms of payment.
        </p>
        <br />
        <p>
          The services offered by contract cleaning companies can vary widely
          depending on the needs of the client. They may include:
        </p>
        <br />
        <b>General cleaning:</b>
        <p>
          This involves routine cleaning tasks such as dusting, vacuuming,
          mopping floors, cleaning restrooms, and emptying trash bins.
        </p>
        <br />
        <b>Deep cleaning:</b>
        <p>
          Periodic deep cleaning services may be included in the contract, which
          involves more intensive cleaning of surfaces, fixtures, and
          hard-to-reach areas.
        </p>
        <br />
        <b>Specialized cleaning:</b>
        <p>
          Some contract cleaning companies offer specialized services such as
          carpet cleaning, window washing, upholstery cleaning, and floor
          stripping and waxing.
        </p>
        <br />
        <b>Disinfection and sanitization:</b>
        <p>
          With the increasing emphasis on hygiene and cleanliness, many contract
          cleaning companies also offer disinfection and sanitization services
          to help prevent the spread of germs and viruses.
        </p>
        <br />
        <b> Exterior cleaning:</b>
        <p>
          In addition to indoor cleaning, contract cleaning companies may also
          offer exterior cleaning services such as pressure washing of building
          exteriors, sidewalks, and parking lots.
        </p>
        <br />
        <p>
          Contract cleaning arrangements offer several benefits to clients,
          including:
        </p>
        <br />
        <b>Convenience:</b>
        <p>
          Clients can outsource their cleaning needs to professional cleaners,
          freeing up time and resources to focus on their core business
          activities.
        </p>
        <br />
        <b>Cost-effectiveness:</b>
        <p>
          Contract cleaning services are often more cost-effective than hiring
          and managing in-house cleaning staff, as clients only pay for the
          services they need, when they need them.
        </p>
        <br />
        <b>Quality assurance: </b>
        <p>
          Professional cleaning companies typically have trained staff,
          specialized equipment, and effective cleaning products to ensure
          high-quality results.
        </p>
        <br />
        <b>Flexibility: </b>
        <p>
          Overall, contract cleaning is a convenient and efficient solution for
          maintaining a clean and healthy environment in various commercial and
          residential settings.
        </p>
        <br />
      </div>
      {/* <div className="p-3">
        
      </div> */}
      <div>
        <h2 className="text-center text-[#23146D] font-bold lg:text-7xl md:text-4xl text-2xl leading-[106.3%] font-gentium-book-basic py-4">
          Tell us how we can help you
        </h2>
        <ServiceForm />
      </div>
    </div>
  );
}

export default PeriodicCleaningModal;
