import Logo from "../assets/logo.png"
import Home from "../assets/Home-bg.png"
import ImgBG from "../assets/bg.png"
import cleaning_service from "../assets/cleaning.png"
import hygiene_market from "../assets/hygiene_market.png"
import hygiene_school from "../assets/hygiene_school.png"
import Seplat from "../assets/seplat.png"
import Dhl from "../assets/DHL.png"
import Jobberman from "../assets/jobberman.png"
import Watersmith from "../assets/watersmith.png"
import Promasidor from "../assets/PROMASIDOR.png"
import Rainiance from "../assets/renaissance.png"
import First_bank from "../assets/firstbank.png"
import Eco_bank from "../assets/eco.png"
import Access_bank from "../assets/access.png"
import  Fcmb_bank from "../assets/fcmb.png"
import Fidelity_bank from "../assets/fidelity.png"
import Greenwich from "../assets/greenwich.png"
import Arbico from "../assets/arbico.png"
import Dufil from "../assets/dufil.png"
import Konga from "../assets/konga.png"
import Transcorp from "../assets/transcorp.png"
import Leasing_plc from "../assets/leasingplc.png"
import Lotus_capital from "../assets/lotus.png"
import Michelin from "../assets/michelin.png"
import Swift from "../assets/swift.png"
import Bet_king from "../assets/bet-king-logo.png"
import Cardinal_stone from "../assets/cardinalstone_logo.png"
import Tvc from "../assets/tvc-news-logo.png"
import Signature_bank from "../assets/Signature-Bank.jpeg"
import Mega_plaza from "../assets/Mega-Plaza-online-mall-Logo.png"
import Choose from "../assets/chooose.svg"
import LearnAbout from "../assets/let's.png"
import About_usBG from "../assets/about_bg.png"
import Digital_service from "../assets/digital-service.png"
import Mission from "../assets/mission_img.svg"
import Team from "../assets/team.jpg"
import Career_hero from "../assets/career_hero.svg"
import Commit_section from "../assets/comm_img.svg"
import BlogHeroSectionBG from "../assets/HeroBG.svg"
import BlogPost_img from "../assets/blog_postBG.svg"
import Class_clean_img from "../assets/class_clean1.svg"
import Choose_clean_img from "../assets/class_clean2.svg"
import All_post_img1 from "../assets/all_post1.svg"
import All_post_img2 from "../assets/all_post2.svg"
import All_post_img3 from "../assets/all_post3.svg"
import Get_IMG from "../assets/get-img.svg"
import Applicate_IMG from "../assets/applicat-img.svg"
import Services_staff_img from "../assets/services_staff.png"











export default {
    Logo,
    Home,
    ImgBG,
    cleaning_service, 
    hygiene_market,
    hygiene_school,
    Seplat,
    Dhl,
    Jobberman,
    Watersmith,
    Promasidor,
    Rainiance,
    First_bank,
    Eco_bank,
    Access_bank,
    Fcmb_bank,
    Fidelity_bank,
    Greenwich,
    Arbico,
    Dufil,
    Konga,
    Transcorp,
    Leasing_plc,
    Lotus_capital,
    Michelin,
    Swift,
    Choose,
    Bet_king,
    Cardinal_stone,
    Tvc,
    Signature_bank,
    Mega_plaza,
    LearnAbout,
    About_usBG,
    Digital_service,
    Mission,
    Team,
    Career_hero,
    Commit_section,
    BlogHeroSectionBG,
    BlogPost_img,
    Class_clean_img,
    Choose_clean_img,
    All_post_img1,
    All_post_img2,
    All_post_img3,
    Get_IMG,
    Applicate_IMG,
    Services_staff_img
}